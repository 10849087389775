import { UnknownObjectAny } from 'global.types';
import { flow, types as t } from 'mobx-state-tree';
import { getStoreInfo } from '../api';
import { searchStores } from '../api/stores';
import { fetchAuthToken } from 'utils/token';

export const LocationModel = t
  .model('Location', {
    id: t.identifier, // demandware id that maps to Amjay CorpStoreId
    name: t.string,
    address1: t.string,
    address2: t.string,
    city: t.string,
    province: t.string,
    postalCode: t.string,
    country: t.string,
    phone: t.string,
    pharmacyPhone: t.maybe(t.string),
    latitude: t.number,
    longitude: t.number,
    image: t.maybe(t.string),
    services: t.array(t.string),
    hours: t.array(t.frozen()),
    email: t.maybe(t.string),
    url: t.maybe(t.string)
  })
  .actions((self) => {
    return {
      fetchPickupDeliveryOptions: flow(function* fetchPharmacyInfo() {
        const pharmacyPhone = self?.pharmacyPhone?.replace(/\D/g, '');
        const result = yield getStoreInfo(pharmacyPhone);
        return parsePickupDeliveryOptions(result?.Store?.row?.[0]?.PickupDeliveryTimeslots);
      })
    };
  });

export const LocationStoreModel = t
  .model({
    locations: t.optional(t.map(LocationModel), {})
  })
  .views((self) => ({
    getAllPharmacyLocations() {
      const allPharmacies: UnknownObjectAny[] = Object.values(self?.locations.toJSON()).sort(
        (a: UnknownObjectAny, b: UnknownObjectAny) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }
      );

      return allPharmacies;
    },
    getAllPharmaciesOrderedByProvince() {
      const allPharmacies: UnknownObjectAny[] = Object.values(self?.locations.toJSON()).sort(
        (a: UnknownObjectAny, b: UnknownObjectAny) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }
      );

      const pharmaciesOrderedByProvince = {
        Alberta: allPharmacies.filter(
          (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'alberta'
        ),
        'British Columbia': allPharmacies.filter(
          (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'british columbia'
        ),
        Saskatchewan: allPharmacies.filter(
          (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'saskatchewan'
        ),
        Manitoba: allPharmacies.filter(
          (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'manitoba'
        )
      };

      return pharmaciesOrderedByProvince;
    },
    getProvinceNames() {
      // const allPharmacies: UnknownObjectAny[] = Object.values(self?.locations.toJSON()).sort(
      //   (a: UnknownObjectAny, b: UnknownObjectAny) => {
      //     if (a.name < b.name) {
      //       return -1;
      //     }
      //     if (a.name > b.name) {
      //       return 1;
      //     }
      //     return 0;
      //   }
      // );

      // const provinceNames = [
      //   ...new Set(
      //     allPharmacies
      //       ?.map((pharmacy: UnknownObjectAny) => pharmacy.province)
      //       .sort((a: UnknownObjectAny, b: UnknownObjectAny) => {
      //         if (a < b) {
      //           return -1;
      //         }
      //         if (a > b) {
      //           return 1;
      //         }
      //         return 0;
      //       })
      //   )
      // ];

      // return provinceNames;
      return ['ALBERTA', 'BRITISH COLUMBIA', 'SASKATCHEWAN', 'MANITOBA']
    },
    getLocatedPharmacies(value: string) {
      try {
        const allPharmacies: UnknownObjectAny[] = Object.values(self?.locations.toJSON()).sort(
          (a: UnknownObjectAny, b: UnknownObjectAny) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }
        );

        if (!allPharmacies) {
          throw 'It appears something went wrong retrieving all the pharmacy options. Please come back later and try again.';
        }

        const pharmaciesOrderedByProvince: UnknownObjectAny = {
          Alberta: allPharmacies.filter(
            (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'alberta'
          ),
          'British Columbia': allPharmacies.filter(
            (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'british columbia'
          ),
          Saskatchewan: allPharmacies.filter(
            (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'saskatchewan'
          ),
          Manitoba: allPharmacies.filter(
            (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'manitoba'
          )
        };

        const locatedPharmacies = [].concat.apply(
          [],
          Object.keys(pharmaciesOrderedByProvince)
            ?.map((key) => {
              return pharmaciesOrderedByProvince?.[key].filter(
                (pharmacy: UnknownObjectAny) =>
                  pharmacy?.postalCode?.toLocaleLowerCase().includes(value?.toLocaleLowerCase()) ||
                  pharmacy?.city?.toLocaleLowerCase().includes(value?.toLocaleLowerCase()) ||
                  pharmacy?.province?.toLocaleLowerCase().includes(value?.toLocaleLowerCase())
              );
            })
            .filter((provinceArray: UnknownObjectAny[] | []) => provinceArray.length > 0)
        );

        return locatedPharmacies;
      } catch (err) {
        console.error(err);
        return 'Error: ' + err;
      }
    },
    getSelectedStoreLocation(selectedPharmacyId: string) {
      return self?.locations.toJSON()?.[selectedPharmacyId];
    },
    getProvincePharmacies(province: string) {
      if (Object.values(self?.locations.toJSON())?.length == 0) {
        this.getAllPharmacyLocations()
      }
      return Object.values(self?.locations.toJSON())?.filter((pharmacy: any) => pharmacy?.province?.toUpperCase() == province);
    }
  }))
  .actions((self) => {
    return {
      fetchAllLocations: flow(function* fetchAllStores() {
        const authToken = yield fetchAuthToken();
        const locations = yield searchStores({}, authToken);
        for (let location of locations) {
          try {
            const parsedLocation = parseLocation(location)
            self.locations.set(location.code, parsedLocation);
          } catch (error) {
            console.error('Error parsing store');
            console.error(error);
          }
        }
      }),

      search: async (criteria: any) => {
        const authToken = await fetchAuthToken();
        const locations = await searchStores(criteria, authToken);
        return locations?.map(parseLocation);
      }
    };
  });

function parseLocation(location: any) {
  const phone = location?.pharmacyPhone ?? location.phone;
  const { stateOrProvince, postalOrZipCode, countryCode, address1, address2, cityOrTown } = location?.address ?? {} as any;
  const url = location?.attributes?.find((attribute: any) => attribute?.fullyQualifiedName?.includes("customstoredetailsurl"))?.values?.[0];
  const { lat, lng } = location?.geo ?? {} as any;
  const services = location?.attributes?.find((attribute: any) => attribute?.fullyQualifiedName === "tenant~services")?.values?.[0];
  const email = location?.attributes?.find((attribute: any) => attribute?.fullyQualifiedName === "tenant~storeemail")?.values?.[0];

  const hours: any[] = [];
  Object.entries(location?.regularHours)?.map(([key, value]) => hours.push({ key: key, value: value }));
  hours?.pop() // remove timezone entry

  return {
    id: location?.code ?? "",
    name: location?.name ?? "",
    address1: address1 ?? "",
    address2: address2 ?? "",
    city: cityOrTown ?? "",
    province: stateOrProvince ?? "",
    postalCode: postalOrZipCode ?? "",
    country: countryCode ?? "",
    phone: phone ?? "",
    pharmacyPhone: location?.pharmacyPhone ?? "",
    latitude: lat ?? 0,
    longitude: lng ?? 0,
    // image,
    services: services ? [...JSON.parse(services)] : [] as string[],
    hours,
    email: email ?? "",
    url: url ?? ""
  };
}

const getProvinceName = (province: string) => {
  switch (province?.toLocaleLowerCase()) {
    case "ab":
      return 'Alberta';
    case "bc":
      return 'British Columbia';
    case "mb":
      return 'Manitoba';
    case "sk":
      return 'Saskatchewan';
    default:
      return province;
  }
}
function parsePickupDeliveryOptions(pdslots: any) {
  return pdslots
    ?.replace(/\r?\n|\r/g, '')
    .split('~')
    .filter((i: number) => i)
    .reduce((acc: any, slot: any) => {
      const [shortcodeLong, description, minutes, successMessage, repeatMessage] = slot.split('|');

      const [pdType, shortcode] = shortcodeLong.split('=');

      return {
        ...acc,
        [pdType]: [
          ...(acc[pdType] || []),
          {
            pdel: pdType,
            shortcode,
            description: sentenceCase(description.replace(/-/, ' - ')),
            minutes,
            successMessage,
            repeatMessage
          }
        ]
      };
    }, {});
}

function sentenceCase(str: string) {
  if (str === null || str === '') return false;
  else str = str.toString();

  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
